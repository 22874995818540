import React, { CSSProperties, PropsWithChildren } from 'react';

import { Grid } from 'components/grid/grid';

import styles from './unicheck-hero.module.scss';

export function UnicheckHero(props: PropsWithChildren<{ id?: string }>) {
    return <div className={styles.unicheckHero} {...props} />;
}

export function UnicheckHeroBackground(props: PropsWithChildren<{ style?: CSSProperties }>) {
    return <div className={styles.unicheckHero__background} {...props} />;
}

export function UnicheckHeroBackgroundLayer(props: PropsWithChildren<{}>) {
    return <div className={styles.unicheckHero__backgroundLayer} {...props} />;
}

export function UnicheckHeroBody(props: PropsWithChildren<{}>) {
    return (
        <Grid className={styles.unicheckHero__body}>
            <div className={styles.unicheckHero__bodyContainer} {...props} />
        </Grid>
    );
}

export function UnicheckHeroImage(props: PropsWithChildren<{}>) {
    return <div className={styles.unicheckHero__image} {...props} />;
}
