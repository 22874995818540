import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { useLax } from 'utils/hooks/lax';
import {
    UnicheckHero,
    UnicheckHeroBackground,
    UnicheckHeroBackgroundLayer,
    UnicheckHeroBody,
    UnicheckHeroImage,
} from 'components/unicheck-hero/unicheck-hero';
import {
    Unicheck,
    UnicheckBackground,
    UnicheckBackgroundLayer,
    UnicheckBody,
    UnicheckBodyText,
} from 'components/unicheck/unicheck';

export default function UnicheckPage(): JSX.Element {
    const data = useStaticQuery(imgQuery);

    useLax();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Phase One Karma — Unicheck.</title>
                <meta name="description" content="Unicheck is a high-performance plagiarism checker for educational, professional, and personal use." />
                <meta property="og:title" content="Phase One Karma — Unicheck" />
            </Helmet>
            <UnicheckHero id="unicheck-hero">
                <UnicheckHeroBackground style={{ maxHeight: '100vh' }}>
                    <UnicheckHeroBackgroundLayer data-lax data-lax-translate-y="0 0, vh 200">
                        <Img fluid={data.clouds1.childImageSharp.fluid} />
                    </UnicheckHeroBackgroundLayer>
                    <UnicheckHeroBackgroundLayer data-lax data-lax-translate-y="0 100, vh 200">
                        <Img fluid={data.clouds2.childImageSharp.fluid} />
                    </UnicheckHeroBackgroundLayer>
                </UnicheckHeroBackground>
                <UnicheckHeroBody>
                    <span>Tackling academic dishonesty with Unicheck, our former company</span>
                    <p>AI-driven anti-plagiarism checker with more than 1 million users worldwide.</p>
                    <UnicheckHeroImage>
                        <Img fluid={data.header1.childImageSharp.fluid} loading="lazy" />
                    </UnicheckHeroImage>
                </UnicheckHeroBody>
            </UnicheckHero>
            <Unicheck>
                <UnicheckBackground>
                    <UnicheckBackgroundLayer
                        style={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            backgroundImage: `url(${require('../img/circles.svg')}`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: '-105px 0',
                            backgroundSize: '2428px 2439px',
                        }}
                    />
                </UnicheckBackground>
                <UnicheckBody>
                    <UnicheckBodyText>
                        <p>
                            <a href="https://unicheck.com" target="_blank">Unicheck</a>{' '}
                            (initially named Unplag) is an AI-driven anti-plagiarism checker launched in 2014 and
                            sold by us in 2020. Unicheck was born out of our desire to make education transparent
                            and improve the value of authentic intellectual work. By making plagiarism checking
                            easy and convenient for both teachers and students, Unicheck served that mission.
                        </p>

                        <p>
                            As a result, by the time we sold Unicheck, it had become a success story with more than
                            1 million users worldwide, including teachers, students, writers and bloggers around
                            the world.
                        </p>
                    </UnicheckBodyText>

                    <span>Keeping the bar of expectations high, P1K set the goal of championing the industry.</span>
                </UnicheckBody>
            </Unicheck>
        </>
    );
}

export const imgQuery = graphql`
    query {
        clouds1: file(relativePath: { eq: "unicheck/clouds-1.png" }) {
            childImageSharp {
                fluid(maxWidth: 2732, srcSetBreakpoints: [2732], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        clouds2: file(relativePath: { eq: "unicheck/clouds-2.png" }) {
            childImageSharp {
                fluid(maxWidth: 2732, srcSetBreakpoints: [2732], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        header1: file(relativePath: { eq: "unicheck/screenshot.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1008, srcSetBreakpoints: [1008], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
    }
`;
