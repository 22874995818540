import React, { CSSProperties, PropsWithChildren } from 'react';

import { Grid } from 'components/grid/grid';

import styles from './unicheck.module.scss';

export function Unicheck(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.unicheck} {...props} />;
}

export function UnicheckBackground(props: PropsWithChildren<{ style?: CSSProperties }>) {
    return <div className={styles.unicheck__background} {...props} />;
}

export function UnicheckBackgroundLayer(props: PropsWithChildren<{ style?: CSSProperties }>) {
    return <div className={styles.unicheck__backgroundLayer} {...props} />;
}

export function UnicheckBody(props: PropsWithChildren<{}>) {
    return (
        <Grid className={styles.unicheck__body}>
            <div className={styles.unicheck__bodyContainer} {...props} />
        </Grid>
    );
}

export function UnicheckBodyText(props: PropsWithChildren<{}>) {
    return <div className={styles.unicheck__bodyText} {...props} />;
}

export function UnicheckImage(props: PropsWithChildren<{ style?: CSSProperties; id?: string }>) {
    return <div className={styles.unicheck__image} {...props} />;
}

export function ProjectData(props: PropsWithChildren<{ style?: CSSProperties }>) {
    return <div className={styles.projectData} {...props} />;
}

export function ProjectDataHeader(props: PropsWithChildren<{ style?: CSSProperties }>) {
    return <div className={styles.projectData__header} {...props} />;
}

export function ProjectDataBody(props: PropsWithChildren<{ style?: CSSProperties }>) {
    return <div className={styles.projectData__body} {...props} />;
}

export function ProjectDataFooter(props: PropsWithChildren<{ style?: CSSProperties }>) {
    return <div className={styles.projectData__footer} {...props} />;
}

export function ProjectDataItem(props: PropsWithChildren<{ style?: CSSProperties }>) {
    return <div className={styles.projectData__item} {...props} />;
}
